// src/firebase/auth.js

import { auth, provider } from "./firebaseConfig";
import { 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  signOut, 
  sendPasswordResetEmail, 
  createUserWithEmailAndPassword 
} from "firebase/auth";

// Function for signing in with email and password
export const signInWithEmail = (email, password) => 
  signInWithEmailAndPassword(auth, email, password);

// Function for signing in with Google
export const signInWithGoogle = () => 
  signInWithPopup(auth, provider);

// Function for signing up with email and password
export const signUpWithEmail = (email, password) => 
  createUserWithEmailAndPassword(auth, email, password);

// Function for signing out the user
export const logout = () => 
  signOut(auth);

// Function for sending a password reset email
export const sendPasswordReset = (email) => 
  sendPasswordResetEmail(auth, email);

// Export the auth instance to access the current user's information
export { auth };
