import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import axios from 'axios';
import { FiChevronLeft, FiTrash, FiPlus } from 'react-icons/fi';

function MoviePicker() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);
  const [newMovieTitle, setNewMovieTitle] = useState('');
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [isHostOrCoHost, setIsHostOrCoHost] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;

    if (!userEmail) {
      alert('User email is missing. Please log in again.');
      navigate('/login');
      return;
    }

    const fetchEventDetails = async () => {
      try {
        const rsvpsCollectionRef = collection(db, 'events', eventId, 'rsvps');
        const rsvpSnapshot = await getDocs(rsvpsCollectionRef);

        let isUserHostOrCoHost = false;
        rsvpSnapshot.forEach((doc) => {
          const rsvpData = doc.data();
          if (rsvpData.email === userEmail && (rsvpData.isHost || rsvpData.isCoHost)) {
            isUserHostOrCoHost = true;
          }
        });

        setIsHostOrCoHost(isUserHostOrCoHost);
      } catch (error) {
        console.error('Error fetching RSVP data:', error);
      }
    };

    fetchEventDetails();

    const unsubscribeMovies = onSnapshot(collection(db, 'events', eventId, 'movieOptions'), (snapshot) => {
      const movieList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMovies(movieList);
      const selected = movieList.find((movie) => movie.selected);
      if (selected) setSelectedMovie(selected);
    });

    return () => {
      unsubscribeMovies();
    };
  }, [eventId, navigate]);

  const handleAddMovie = async () => {
    const storedUser = localStorage.getItem('user');
    const userEmail = storedUser ? JSON.parse(storedUser).email : null;
    const apiKey = process.env.REACT_APP_OMDB_API_KEY;

    if (newMovieTitle.trim() !== '') {
      try {
        const apiUrl = `https://www.omdbapi.com/?t=${encodeURIComponent(newMovieTitle)}&apikey=${apiKey}`;
        const response = await axios.get(apiUrl);

        if (response.data && response.data.Response === 'True') {
          const movieData = {
            title: response.data.Title,
            posterURL: response.data.Poster !== 'N/A' ? response.data.Poster : '',
            rating: response.data.imdbRating || 'N/A',
            addedBy: userEmail,
            selected: false,
            apiUrl,
          };

          await addDoc(collection(db, 'events', eventId, 'movieOptions'), movieData);
          setNewMovieTitle('');
        } else {
          alert(`Error: ${response.data.Error || 'Movie not found. Please try another title.'}`);
        }
      } catch (error) {
        console.error('Error adding movie:', error);
        alert('An error occurred while fetching movie details. Check the console for more details.');
      }
    }
  };

  const handlePickRandomMovie = async () => {
    if (movies.length > 0) {
      const randomMovie = movies[Math.floor(Math.random() * movies.length)];
      try {
        // Unselect any previously selected movie
        const selectedMovies = movies.filter(movie => movie.selected);
        for (const movie of selectedMovies) {
          await updateDoc(doc(db, 'events', eventId, 'movieOptions', movie.id), {
            selected: false,
          });
        }

        // Mark the new random movie as selected
        await updateDoc(doc(db, 'events', eventId, 'movieOptions', randomMovie.id), {
          selected: true,
        });

        setSelectedMovie(randomMovie);
        alert(`Selected movie: ${randomMovie.title}`);
      } catch (error) {
        console.error('Error updating selected movie:', error);
      }
    }
  };

  const handleDeleteMovie = async (movieId) => {
    try {
      await deleteDoc(doc(db, 'events', eventId, 'movieOptions', movieId));
      alert('Movie deleted successfully.');
    } catch (error) {
      console.error('Error deleting movie:', error);
      alert('An error occurred while deleting the movie.');
    }
  };

  return (
    <div className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite">
      <div className="max-w-5xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <button
            className="flex items-center gap-2 text-gray-700 hover:text-gray-900 transition text-lg"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft /> Back
          </button>
          <button
            className="text-gray-700 hover:text-gray-900 transition text-lg"
            onClick={() => navigate('/')}
          >
            Logout
          </button>
        </div>

        <h2 className="text-2xl font-bold text-gray-800 mb-4">Movie Picker</h2>

        {isHostOrCoHost && (
          <div className="mb-4">
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Add a movie title"
                value={newMovieTitle}
                onChange={(e) => setNewMovieTitle(e.target.value)}
                className="p-2 border border-gray-300 rounded-l-lg w-full focus:outline-none focus:ring-2 focus:ring-darkGreen"
              />
              <button
                onClick={handleAddMovie}
                className="bg-darkGreen text-white px-4 py-2 rounded-r-lg shadow-md hover:bg-darkGreen hover:shadow-lg transition-all duration-200 flex items-center"
              >
                <FiPlus size={20} className="inline" />
              </button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 mb-4">
          {movies.map((movie) => (
            <div
              key={movie.id}
              className={`flex flex-col items-center p-2 bg-white shadow-sm rounded-lg transform hover:scale-105 transition-transform duration-300 ${
                movie.selected ? 'border-4 border-darkGreen' : 'border'
              }`}
            >
              <img
                src={movie.posterURL}
                alt={movie.title}
                className="w-20 h-32 object-cover rounded-md mb-2"
              />
              <div className="text-center">
                <h3 className="text-sm font-bold text-gray-800 truncate">{movie.title}</h3>
                <p className="text-xs text-gray-600">Rating: {movie.rating}</p>
              </div>
              {isHostOrCoHost && (
                <button
                  onClick={() => handleDeleteMovie(movie.id)}
                  className="text-red-500 hover:text-red-700 transition mt-1"
                >
                  <FiTrash size={16} title="Delete Movie" />
                </button>
              )}
            </div>
          ))}
        </div>

        {isHostOrCoHost && (
          <button
            onClick={handlePickRandomMovie}
            className="w-full bg-darkGreen text-white py-2 rounded-lg shadow-md hover:bg-darkGreen hover:shadow-lg transition-all duration-200"
          >
            Pick Randomly!
          </button>
        )}

        {selectedMovie && (
          <div className="mt-4 p-3 bg-pastellavender rounded-lg shadow-md">
            <h3 className="text-lg font-bold text-center text-gray-800 mb-2">Selected Movie</h3>
            <div className="text-center">
              <img
                src={selectedMovie.posterURL}
                alt={selectedMovie.title}
                className="w-24 h-36 object-cover mx-auto rounded-md mb-2"
              />
              <h4 className="text-md font-semibold">{selectedMovie.title}</h4>
              <p className="text-sm text-gray-600">Rating: {selectedMovie.rating}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MoviePicker;
