import React, { useEffect, useState } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

function LeaderboardPage() {
  const [leaders, setLeaders] = useState({
    eventMasters: [],
    partyRegulars: [],
    foodHeroes: [],
    chatterChamps: []
  });

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = async () => {
    const categories = ['eventMasters', 'partyRegulars', 'foodHeroes', 'chatterChamps'];
    const data = {};

    for (const category of categories) {
      const q = query(collection(db, 'Leaderboard', category), orderBy('count', 'desc'), limit(3));
      const snapshot = await getDocs(q);
      data[category] = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    }

    setLeaders(data);
  };

  return (
    <div className="flex flex-col items-center bg-offwhite p-8">
      <h2 className="text-2xl font-bold mb-6">Party Leaderboard</h2>
      
      {['Event Masters', 'Party Regulars', 'Food Heroes', 'Chatter Champs'].map((title, index) => (
        <div key={title} className="w-full max-w-lg mb-8">
          <h3 className="text-xl font-semibold mb-4">{title}</h3>
          <ul>
            {leaders[Object.keys(leaders)[index]].map((user, rank) => (
              <li key={user.id} className="flex items-center gap-4 p-2">
                <span className="text-lg font-bold">{rank + 1}.</span>
                <img src={user.photo || '/default-avatar.png'} alt="User" className="h-10 w-10 rounded-full" />
                <span>{user.firstName || 'Anonymous'}</span>
                <span className="ml-auto font-semibold">{user.count}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default LeaderboardPage;
