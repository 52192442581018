import React from 'react';
import AppRoutes from './routes/AppRoutes'; // Importing the AppRoutes component

function App() {
  return (
    <div className="App">
      <AppRoutes />  {/* Use AppRoutes to handle all routing */}
    </div>
  );
}

export default App;
