import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { collection, addDoc, deleteDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { FiArrowLeft, FiTrash2, FiEdit3, FiSave, FiX, FiMail } from 'react-icons/fi';
import './RSVPManager.css';  // Import CSS for animation styles
import logo from '../../assets/images/logos/logo-partysync-official.png';
import { logout } from '../../firebase/auth';

function RSVPManager() {
  const { eventId } = useParams();
  const [rsvps, setRsvps] = useState([]);
  const [newGuest, setNewGuest] = useState({
    name: '',
    email: '',
    allergyInfo: '',
    isCoHost: false,
    isHost: false
  });
  const [editGuestId, setEditGuestId] = useState(null);
  const [editGuestData, setEditGuestData] = useState({});
  const [isEnvelopeFlying, setIsEnvelopeFlying] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Set up Firestore listener for RSVPs
    const unsubscribeRSVP = onSnapshot(collection(db, 'events', eventId, 'rsvps'), snapshot => {
      setRsvps(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });

    return () => {
      unsubscribeRSVP();
    };
  }, [eventId]);

  const [totalGuests, setTotalGuests] = useState(0);
  const [totalConfirmed, setTotalConfirmed] = useState(0); // Define totalConfirmed state
  const [totalDogs, setTotalDogs] = useState(0);
  

  useEffect(() => {
    // Calculate total guests, confirmed guests (including additional guests), and dogs
    const guests = rsvps.length;
    const confirmed = rsvps.reduce((sum, rsvp) => {
      if (rsvp.response === 'Yes') {
        return sum + 1 + (rsvp.additionalGuests || 0); // 1 for the main guest + additional guests
      }
      return sum;
    }, 0);
    const dogs = rsvps.reduce((sum, rsvp) => sum + (rsvp.additionalDogs || 0), 0);
  
    setTotalGuests(guests);
    setTotalConfirmed(confirmed); // Set totalConfirmed
    setTotalDogs(dogs);
  }, [rsvps]);
  

  const handleAddGuest = async (e) => {
    e.preventDefault();
    if (newGuest.name.trim() === "" || newGuest.email.trim() === "") {
      return;
    }
    await addDoc(collection(db, 'events', eventId, 'rsvps'), {
      name: newGuest.name,
      email: newGuest.email,
      allergyInfo: newGuest.allergyInfo || '',
      response: 'Pending',
      isCoHost: false,
      isHost: false
    });
    setNewGuest({ name: '', email: '', allergyInfo: '', isCoHost: false, isHost: false });

    // Trigger envelope animation
    setIsEnvelopeFlying(true);
    setTimeout(() => {
      setIsEnvelopeFlying(false);
    }, 3000); // Set duration to match the CSS animation duration
  };

  const handleRemoveGuest = async (guestId) => {
    const confirmRemoval = window.confirm("Are you sure you want to remove this guest?");
    if (confirmRemoval) {
      await deleteDoc(doc(db, 'events', eventId, 'rsvps', guestId));
    }
  };

  const handleEditGuest = (guest) => {
    setEditGuestId(guest.id);
    setEditGuestData(guest);
  };

  const handleSaveGuest = async () => {
    await updateDoc(doc(db, 'events', eventId, 'rsvps', editGuestId), editGuestData);
    setEditGuestId(null);
  };

  const handleCancelEdit = () => {
    setEditGuestId(null);
    setEditGuestData({});
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditGuestData((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleCoHost = async (guestId, currentStatus) => {
    await updateDoc(doc(db, 'events', eventId, 'rsvps', guestId), { isCoHost: !currentStatus });
  };

  const handleLogout = () => {
    navigate('/'); // Redirect to home or logout route
  };

  return (
    <div className="flex flex-col min-h-screen">
    {/* Standardized Header */}
    <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
      <img
        src={logo}
        alt="PartySync Logo"
        className="h-16 cursor-pointer"
   //     onClick={() => navigate('/')}
      />
      <button
        className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
        onClick={handleLogout}
      >
      LOG OUT
      </button>
    </header>

    <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
      {/* Back Button Below Header */}
      <div className="flex justify-start items-center mb-4">
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={() => navigate(-1)}
        >
          <FiArrowLeft size={32} />
        </button>
      </div>

        <div className="max-w-7xl mx-auto">
          {/* RSVP Management Title */}
          <h2 className="text-2xl font-bold uppercase text-fontGreen mb-8">RSVP Manager</h2>
          <div className="mb-8 p-4 bg-white border-4 border-fontGreen rounded-2xl">
  <h3 className="text-xl font-bold text-fontGreen uppercase mb-4">RSVP Stats</h3>
  <div className="flex flex-col sm:flex-row justify-between">
    <div className="text-lg font-semibold text-fontGreen">
      Invites Sent: <span className="font-bold">{totalGuests}</span>
    </div>
    <div className="text-lg font-semibold text-fontGreen">
  Confirmed Guests: <span className="font-bold">{totalConfirmed}</span>
</div>

    <div className="text-lg font-semibold text-fontGreen">
      Dogs: <span className="font-bold">{totalDogs}</span>
    </div>
  </div>
</div>

          {/* Floating Envelope Animation */}
          {isEnvelopeFlying && (
            <div className="envelope-float-animation">
              <FiMail size={48} className="text-fontGreen" />
              <div className="particles-container">
                <div className="particle"></div>
                <div className="particle"></div>
                <div className="particle"></div>
                <div className="particle"></div>
              </div>
            </div>
          )}

          {/* Existing Guests and Add New Guest in Grid Layout */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {/* Add New Guest Card */}
            <div className="border-2 border-dashed border-bold border-fontGreen p-6 rounded-xl flex flex-col items-center justify-center cursor-pointer hover:scale-105 transition relative">
              <form onSubmit={handleAddGuest} className="flex flex-col gap-4 mt-4 w-full">
                <input
                  type="text"
                  value={newGuest.name}
                  onChange={(e) => setNewGuest({ ...newGuest, name: e.target.value })}
                  placeholder="Guest Name"
                  className="p-4 border border-fontGreen rounded w-full text-lg text-fontGreen"
                  required
                />
                <input
                  type="email"
                  value={newGuest.email}
                  onChange={(e) => setNewGuest({ ...newGuest, email: e.target.value })}
                  placeholder="Guest Email"
                  className="p-4 border border-fontGreen rounded w-full text-lg text-fontGreen"
                  required
                />
                <input
                  type="text"
                  value={newGuest.allergyInfo}
                  onChange={(e) => setNewGuest({ ...newGuest, allergyInfo: e.target.value })}
                  placeholder="Allergy Information (Optional)"
                  className="p-4 border border-fontGreen rounded w-full text-lg text-fontGreen"
                />
                <button
                  type="submit"
                  className="bg-fontGreen text-white py-3 px-6 rounded-lg hover:scale-105 transition text-lg"
                >
                  Add Guest
                </button>
              </form>
            </div>

            {/* Guest Cards */}
            {rsvps.map(rsvp => (
  <div key={rsvp.id} className="border border-4 border-fontGreen p-6 rounded-xl bg-white hover: transition relative">
    {editGuestId === rsvp.id ? (
      // Edit Mode
      <div>
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            name="name"
            value={editGuestData.name}
            onChange={handleEditChange}
            className="p-2 border border-secondary rounded w-full text-xl font-bold text-fontGreen mb-2"
            required
          />
          <div className="flex gap-3">
            <button onClick={handleSaveGuest} className="text-fontGreen font-bold hover:text-fontGreen transition">
              <FiSave size={24} />
            </button>
            <button onClick={handleCancelEdit} className="text-red-500 hover:text-red-700 transition">
              <FiX size={24} />
            </button>
          </div>
        </div>
        <input
          type="email"
          name="email"
          value={editGuestData.email}
          onChange={handleEditChange}
          className="p-2 border border-secondary rounded w-full text-lg font-bold text-fontGreen mb-2"
          required
        />
        <input
          type="text"
          name="allergyInfo"
          value={editGuestData.allergyInfo}
          onChange={handleEditChange}
          className="p-2 border border-secondary rounded w-full text-lg font-bold mb-2"
          placeholder="Allergy Information (Optional)"
        />
      </div>
    ) : (
      // View Mode
      <div>
        <div className="flex justify-between items-center mb-4">
          <p className="font-bold text-fontGreen text-xl">{rsvp.name}</p>
          <div className="flex gap-3">
            <button onClick={() => handleEditGuest(rsvp)} className="text-fontGreen font-bold hover:scale-105 text-fontGreen transition">
              <FiEdit3 size={24} />
            </button>
            <button onClick={() => handleRemoveGuest(rsvp.id)} className="text-red-500 hover:scale-105 text-red-700 transition">
              <FiTrash2 size={24} />
            </button>
          </div>
        </div>
        <p className="text-lg text-fontGreen">Email: <span className="text-md">{rsvp.email}</span></p>
        <p className="text-lg text-fontGreen">RSVP Status: <span className="font-medium">{rsvp.response}</span></p>
        <p className="text-lg text-fontGreen">Allergy Info: <span className="font-medium">{rsvp.allergyInfo || 'None'}</span></p>
        
        {/* Additional Guests and Dogs fields */}
        <p className="text-lg text-fontGreen">Additional Guests: <span className="font-medium">{rsvp.additionalGuests || 0}</span></p>
        <p className="text-lg text-fontGreen">Dogs: <span className="font-medium">{rsvp.additionalDogs || 0}</span></p>
        
        <div className="flex items-center mt-4">
          <button
            onClick={() => handleToggleCoHost(rsvp.id, rsvp.isCoHost)}
            className={`text-lg font-semibold px-4 py-2 rounded-lg ${
              rsvp.isCoHost ? 'bg-fontGreen text-white' : 'bg-secondary text-white'
            } hover:bg-fontGreen transition`}
          >
            {rsvp.isCoHost ? 'Co-Host' : 'Assign as Co-Host'}
          </button>
        </div>
      </div>
    )}
  </div>
))}

          </div>
        </div>
      </main>
    </div>
  );
}

export default RSVPManager;
