import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmail, signInWithGoogle, signUpWithEmail } from '../../firebase/auth';
import { db } from '../../firebase/firebaseConfig';
import {
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  increment,
} from 'firebase/firestore';
import partyImage1 from '../../assets/images/logos/logo-partysync-bach.png';
import partyImage2 from '../../assets/images/logos/logo-partysync-bday.png';
import partyImage3 from '../../assets/images/logos/logo-partysync-chef.png';
import partyImage4 from '../../assets/images/logos/logo-partysync-grad.png';
import partyImage5 from '../../assets/images/logos/logo-partysync-frame.png';
import logo from '../../assets/images/logos/logo-partysync-official.png';
import logotext from '../../assets/images/logos/logo-partysync-text-v2.png';
import backgroundImage from '../../assets/images/login-background.png'; // Added import for the background image
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../firebase/firebaseConfig'; // Ensure auth is configured

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(true);
  const [error, setError] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const [currentStatementIndex, setCurrentStatementIndex] = useState(0);
  const statements = [
    "Throw a birthday bash",
    "Host a Bridal Shower",
    "Plan Bachelorette",
    "Halloween Party",
    "Organize a Dinner Party",
    "Thanksgiving Dinner",
    "Plan a Friendsgiving",
    "Plan a Surprise Party",
    "Family Reunion",
    "Plan a Graduation Party",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStatementIndex((prevIndex) => (prevIndex + 1) % statements.length);
    }, 1800);

    return () => clearInterval(interval);
  }, [statements.length]);

  const images = [partyImage1, partyImage2, partyImage3, partyImage4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3500);

    return () => clearInterval(interval);
  }, [images.length]);

  const updateUserInDB = async (user) => {
    if (!user) return;

    const userRef = doc(db, 'Users', user.uid);
    const displayName = user.displayName || "";
    const nameParts = displayName.split(" ");
    const firstName = nameParts[0] || "";
    const lastName = nameParts.slice(1).join(" ") || "";

    const userData = {
      email: user.email,
      firstName: firstName,
      lastName: lastName,
      photo: user.photoURL || "",
      signupDate: serverTimestamp(),
      lastLogin: serverTimestamp(),
      loginCount: 1,
      subscriptionLevel: "beta" // Explicitly set subscription level to "beta"
    };
  
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        // Update only lastLogin and loginCount for existing users
        await updateDoc(userRef, {
          lastLogin: serverTimestamp(),
          loginCount: increment(1),
        });
        console.log('Existing user logged in, updating last login and count');
      } else {
        // Set full data for new users
        await setDoc(userRef, userData);
        console.log('New user created with beta subscription level');
      }
      console.log('User data successfully updated/created in Firestore');
    } catch (error) {
      console.error('Error updating user in Firestore:', error);
    }
  };


  const handleForgotPassword = async () => {
    // Prompt the user to enter their email
    const emailForReset = prompt("Please enter your email for password reset:");
  
    // Check if an email was entered
    if (!emailForReset) {
      setError("Email is required to reset password.");
      return;
    }
  
    try {
      // Send the password reset email
      await sendPasswordResetEmail(auth, emailForReset);
      setError(""); // Clear any previous error
      alert("Password reset email sent! Please check your inbox."); // Notify success
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setError("No account found with this email. Please sign up first.");
      } else {
        setError("Failed to send password reset email. Please try again later.");
      }
    }
  };
    
  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError("");
    console.log("Login Button Clicked"); // Debug log
    
    if (email && password) {
      try {
        const userCredential = await signInWithEmail(email, password);
        const user = userCredential.user;
        console.log("User Logged In:", user); // Debug log
        localStorage.setItem('user', JSON.stringify({ uid: user.uid, email: user.email }));
        await updateUserInDB(user);
    
        // Retrieve redirectEventId from localStorage
        const redirectEventId = localStorage.getItem('redirectEventId');
        if (redirectEventId) {
          console.log("Redirecting to guest event page with ID:", redirectEventId); // Debug log
          localStorage.removeItem('redirectEventId');
          navigate(`/guest-event/${redirectEventId}`);
        } else {
          console.log("Redirecting to role selection page"); // Debug log
          navigate('/role-selection');
        }
      } catch (err) {
        console.error("Login Error:", err.message); // Debug log
        setError(err.message);
      }
    } else {
      setError("Please fill out both fields.");
    }
  };
  
  

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    setError("");
    console.log("Sign Up Button Clicked"); // Debug log to confirm the button works
    
    if (email && password) {
      try {
        const userCredential = await signUpWithEmail(email, password);
        const user = userCredential.user;
        console.log("User Signed Up:", user); // Debug log
        localStorage.setItem('user', JSON.stringify({ uid: user.uid, email: user.email }));
        await updateUserInDB(user);
    
        // Retrieve redirectEventId from localStorage
        const redirectEventId = localStorage.getItem('redirectEventId');
        if (redirectEventId) {
          console.log("Redirecting to guest event page with ID:", redirectEventId); // Debug log
          localStorage.removeItem('redirectEventId');
          navigate(`/guest-event/${redirectEventId}`);
        } else {
          console.log("Redirecting to role selection page"); // Debug log
          navigate('/role-selection');
        }
      } catch (err) {
        console.error("Sign Up Error:", err.message); // Debug log
        setError(err.message);
      }
    } else {
      setError("Please fill out both fields.");
    }
  };
  
  
  

  const handleGoogleLogin = async () => {
    setError("");
    try {
      const result = await signInWithGoogle();
      const user = result.user;
      localStorage.setItem('user', JSON.stringify({ uid: user.uid, email: user.email }));
      await updateUserInDB(user);

      const redirectEventId = localStorage.getItem('redirectEventId');
      if (redirectEventId) {
        localStorage.removeItem('redirectEventId');
        navigate(`/guest-event/${redirectEventId}`);
      } else {
        navigate('/role-selection');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="w-full py-8 bg-white text-fontGreen text-md md:text-xl font-bold flex justify-between items-center px-4 md:px-8 fixed top-0 z-10">
        <img
          src={logotext}
          alt="PartySync Logo"
          className="h-6 md:h-6 cursor-pointer"
          onClick={() => navigate('/')}
        />
        <nav className="flex gap-4 md:gap-6 font-bold">
          <a href="/" className="hover:underline text-md md:text-base">ABOUT</a>
          <a href="/" className="hover:underline text-md md:text-base">CONTACT</a>
        </nav>
      </header>
  
      <main className="flex flex-col md:flex-row flex-1 mt-20 mb-16">
        {/* Left section with background image */}
        <div
          className="md:w-1/2 w-full flex items-center justify-center relative p-4"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="text-white text-2xl md:text-4xl font-bold animate-fade-in-out text-center">
            {statements[currentStatementIndex]}
          </div>
        </div>
  
        <div className="md:w-1/2 w-full flex flex-col items-center justify-center bg-gradient-to-r from-offwhite to-offwhite p-6 md:p-8 text-black">
          <img
            src={logo}
            alt="PartySync Logo"
            className="mb-6 md:mb-8 w-1/3 md:w-1/4 max-w-xs"
          />
          <p className="text-lg md:text-xl text-fontGreen font-bold uppercase mb-6 md:mb-8">This Way To The Party</p>
  
          <button
            onClick={handleGoogleLogin}
            className="mb-4 md:mb-6 w-full max-w-xs bg-white text-fontGreen text-2xl py-2 md:py-3 px-4 rounded-full border-2 border-fontGreen font-bold flex items-center justify-center hover:bg-gray-100 transition"
          >
            <img src="https://www.svgrepo.com/show/355037/google.svg" alt="Google" className="w-4 md:w-5 h-4 md:h-5 mr-2" />
            Continue with Google
          </button>
  
          <div className="flex items-center mb-4 w-full max-w-xs">
            <hr className="flex-grow border-primary" />
            <span className="mx-2 text-black text-sm md:text-base">or</span>
            <hr className="flex-grow border-black" />
          </div>
  
          <form onSubmit={isSignUp ? handleEmailSignUp : handleEmailLogin} className="flex flex-col gap-2 w-full max-w-xs">
          <label htmlFor="email" className="text-md uppercase font-bold text-fontGreen">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 border-2 border-fontGreen rounded-full text-black"
            required
          />

          {/* Password Label and Forgot Password Link Container */}
          <div className="flex justify-between items-center mt-3">
            <label htmlFor="password" className="text-md uppercase font-bold text-fontGreen">Password</label>
            {!isSignUp && (
              <p
                className="text-sm font-semibold text-blue-600 cursor-pointer hover:underline"
                onClick={() => handleForgotPassword()}
              >
                Forgot password?
              </p>
            )}
          </div>

          <input
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-2 border-2 border-fontGreen rounded-full text-black"
            required
          />

          <button type="submit" className="bg-primary text-white py-2 px-4 rounded-full font-bold hover:bg-accent transition mt-3">
            {isSignUp ? 'Sign Up' : 'Log In'}
          </button>
        </form>
  
          {/* Error Message */}
          {error && <p className="text-red-500 text-center mt-4">{error}</p>}
  
          {/* Toggle between Sign Up and Login */}
          <p
            className="mt-4 md:mt-6 text-sm md:text-base text-black text-center font-bold cursor-pointer hover:underline"
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp ? 'Already a member? Sign In' : 'New here? Sign Up'}
          </p>
        </div>
      </main>
  
      <footer className="w-full py-2 md:py-4 bg-white text-black flex justify-between items-center px-4 md:px-8 fixed bottom-0 z-10">
        <p className="text-xs md:text-sm">&copy; {new Date().getFullYear()} PartySync. All Rights Reserved.</p>
      </footer>
    </div>
  );
  
  
}

export default Login;
