// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYTPWOvGWzCdDYJhtks4Bt5LN9YOBBzr4",
  authDomain: "partysync-v1.firebaseapp.com",
  projectId: "partysync-v1",
  storageBucket: "partysync-v1.appspot.com",
  messagingSenderId: "701220582813",
  appId: "1:701220582813:web:23c3e44472a16d2a62ab74",
  measurementId: "G-SHGG5XCRS1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics (optional, only for web)
const analytics = getAnalytics(app);

// Initialize Firebase Auth and Google Provider
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Initialize Firestore
const db = getFirestore(app);


// Export the services for use in other parts of the app
export { app, auth, provider, db, analytics };
