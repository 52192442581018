import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/firebaseConfig';
import { collection, getDocs, addDoc, doc, setDoc, query, where, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { logout } from '../../firebase/auth';
import { FiLogOut, FiArrowLeft, FiPlus, FiCalendar } from 'react-icons/fi';
import logo from '../../assets/images/logos/logo-partysync-official.png';

function HostDashboard() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    name: '',
    description: '',
    date: '',
    time: '',
    location: '',
    type: 'Birthday'
  });
  const [subscriptionLevel, setSubscriptionLevel] = useState('free'); // Default to 'free' level
  const navigate = useNavigate();
  const formatTime = (time) => {
    if (!time) return ''; // Return an empty string if time is undefined
  
    const [hour, minute] = time.split(':');
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? 'PM' : 'AM';
    const formattedHour = hourInt % 12 || 12; // Convert hour to 12-hour format, where 0 becomes 12
    return `${formattedHour}:${minute} ${ampm}`;
  };
  
  
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Fetch events and subscription level for the user
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('User is not authenticated');
          setIsLoading(false);
          setIsButtonDisabled(false); // Enable button even if user isn't authenticated
          return;
        }
  
        // Fetch user subscription level
        const userDocRef = doc(db, 'Users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setSubscriptionLevel(userData.subscriptionLevel || 'free');
        }
  
        // Fetch events where the user is host
        const eventsCollection = collection(db, 'events');
        const hostQuery = query(eventsCollection, where('hostId', '==', user.uid));
        const hostSnapshot = await getDocs(hostQuery);
        const hostEvents = hostSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          role: 'Host'
        }));
  
        // Fetch events where the user is a co-host by checking RSVPs
        const coHostEvents = [];
        const eventSnapshot = await getDocs(eventsCollection);
        for (const doc of eventSnapshot.docs) {
          const rsvpsCollection = collection(db, `events/${doc.id}/rsvps`);
          const rsvpsSnapshot = await getDocs(rsvpsCollection);
          rsvpsSnapshot.forEach(rsvpDoc => {
            const rsvpData = rsvpDoc.data();
            if (rsvpData.email === user.email && rsvpData.isCoHost) {
              coHostEvents.push({
                id: doc.id,
                ...doc.data(),
                role: 'Co-Host'
              });
            }
          });
        }
  
        setEvents([...hostEvents, ...coHostEvents]);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setIsLoading(false);
        setIsButtonDisabled(false); // Enable the button after loading completes
      }
    };
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchEvents();
      } else {
        setIsLoading(false);
        setEvents([]);
        setIsButtonDisabled(false); // Enable button even if user isn't authenticated
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEvent(prevEvent => ({
      ...prevEvent,
      [name]: value
    }));
  };

  const handleCreateEvent = async (e) => {
    e.preventDefault();
  
    const hostedEventsCount = events.filter(event => event.role === 'Host').length;
  
    if (subscriptionLevel !== 'pro' && hostedEventsCount >= 2) {
      alert("Beta users are only allowed 2 events. Upgrade to PartySync Pro.");
      return;
    }
  
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('User is not authenticated');
        return;
      }
  
      const eventsCollection = collection(db, 'events');
      const newEventData = { 
        ...newEvent, 
        hostId: user.uid, 
        hostEmail: user.email 
      }; // Add time to the newEventData
  
      const docRef = await addDoc(eventsCollection, newEventData);
  
      const rsvpsCollection = collection(db, `events/${docRef.id}/rsvps`);
      await setDoc(doc(rsvpsCollection, user.uid), {
        name: user.displayName || 'Add_Host_Name',
        email: user.email,
        isHost: true,
        response: 'Yes'
      });
  
      const newCreatedEvent = { ...newEventData, id: docRef.id, role: 'Host' };
      setEvents(prevEvents => [...prevEvents, newCreatedEvent]);
      closeModal();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };
  
  


  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Standardized Header */}
      <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
        <img
          src={logo}
          alt="PartySync Logo"
          className="h-16 cursor-pointer"
     //     onClick={() => navigate('/')}
        />
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={handleLogout}
        >
          LOG OUT
        </button>
      </header>

      <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
        {/* Back Button Below Header */}
        <div className="flex justify-start items-center mb-4">
          <button
            className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
            onClick={() => navigate('/role-selection')}
          >
            <FiArrowLeft size={32} />
          </button>
        </div>

        <div className="max-w-7xl mx-auto">
          {/* Title */}
          <h2 className="text-2xl font-bold text-fontGreen mb-6">YOUR EVENTS</h2>

          {/* Create New Event Card */}
{/* Create New Event Card */}
<div
  onClick={!isButtonDisabled ? openModal : null} // Only allow click if button is not disabled
  className={`p-4 mb-6 rounded-2xl bg-yellow flex flex-col items-center justify-center transition transform ${
    isButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:scale-105'
  }`}
>
  <FiPlus size={48} className="text-white mb-2" />
  <p className="text-xl font-bold text-white">Create New Event</p>
</div>



          {/* Event List */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {isLoading ? (
              Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className="animate-pulse bg-pastelRedBrown p-6 rounded-2xl shadow-lg cursor-pointer"
                >
                  <div className="h-6 bg-darkRedBrown rounded w-3/4 mb-2"></div>
                  <div className="h-4 bg-darkRedBrown rounded w-1/2 mb-2"></div>
                  <div className="h-4 bg-darkRedBrown rounded w-1/4 mb-2"></div>
                  <div className="h-4 bg-darkRedBrown rounded w-1/3 mb-2"></div>
                </div>
              ))
            ) : (
              events.map(event => (
                <div
                  key={event.id}
                  onClick={() => navigate(`/host-dashboard/event/${event.id}`)}
                  className="bg-gradient-to-r from-pink to-pink text-white p-6 rounded-2xl cursor-pointer hover: transition transform hover:scale-105 relative"
                >
                  <h3 className="text-2xl text-fontGreen font-bold mb-2 flex items-center gap-2">
                    {event.name}
                  </h3>
                  <p className="text-lg text-fontGreen mb-2">{event.description}</p>
                  <p className="text-lg text-fontGreen">
                    Date: <span className="font-medium">{event.date}</span>
                  </p>
                  <p className="text-lg text-fontGreen">
                     Time: <span className="font-medium">{formatTime(event.time)}</span>
                  </p>
                  <p className="text-lg text-fontGreen">
                    Location: <span className="font-medium">{event.location}</span>
                  </p>
                  <span
                    className={`absolute bottom-2 right-2 text-xs text-darkgrey font-semibold py-1 px-2 rounded-full ${
                      event.role === 'Host' ? 'bg-white' : 'bg-white'
                    }`}
                  >
                    {event.role}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Modal for Creating New Event */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-darkgrey bg-opacity-90 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
              <h3 className="text-xl font-semibold text-darkgrey mb-4">Create New Event</h3>
              <form onSubmit={handleCreateEvent} className="flex flex-col gap-4">
                <input
                  type="text"
                  name="name"
                  placeholder="Event Name"
                  value={newEvent.name}
                  onChange={handleChange}
                  className="p-3 border border-secondary rounded focus:outline-none focus:border-primary"
                  required
                />
                <textarea
                  name="description"
                  placeholder="Event Description"
                  value={newEvent.description}
                  onChange={handleChange}
                  className="p-3 border border-secondary rounded focus:outline-none focus:border-primary"
                  required
                />
                <input
                  type="date"
                  name="date"
                  value={newEvent.date}
                  onChange={handleChange}
                  className="p-3 border border-secondary rounded focus:outline-none focus:border-primary"
                  required
                />
                <input
                  type="time"
                  name="time"
                  value={newEvent.time}
                  onChange={handleChange}
                  className="p-3 border border-secondary rounded focus:outline-none focus:border-primary"
                  required
/>

                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={newEvent.location}
                  onChange={handleChange}
                  className="p-3 border border-secondary rounded focus:outline-none focus:border-primary"
                  required
                />
                <select
                  name="type"
                  value={newEvent.type}
                  onChange={handleChange}
                  className="p-3 border border-secondary rounded focus:outline-none focus:border-primary"
                >
                  <option value="Birthday">Birthday</option>
                  <option value="Anniversary">Anniversary</option>
                  <option value="Graduation">Graduation</option>
                  <option value="Bachelorette">Bachelorette</option>
                  <option value="Dinner">Dinner</option>
                  <option value="Thanksgiving">Thanksgiving</option>
                  <option value="Baby Shower">Baby Shower</option>
                  <option value="Bridal Shower">Bridal Shower</option>
                  <option value="Other">Other</option>
                </select>
                <div className="flex justify-between mt-4">
                  <button
                    type="button"
                    className="bg-secondary text-white py-2 px-4 rounded hover:bg-accent transition"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary text-white py-2 px-4 rounded hover:bg-accent transition"
                  >
                    Create Event
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default HostDashboard;
