import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
import { logout } from '../../firebase/auth';
import { FiLogOut, FiArrowLeft, FiCalendar } from 'react-icons/fi';
import logo from '../../assets/images/logos/logo-partysync-official.png'; // Update the path if necessary

function GuestDashboard() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('All'); // State for filtering events
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const user = JSON.parse(localStorage.getItem('user')); // Current logged-in user
      if (!user || (!user.uid && !user.email)) {
        console.error('User not found in local storage, please login again.');
        navigate('/'); // Redirect to login if user info is missing
        return;
      }

      const eventsRef = collection(db, 'events');
      setLoading(true);

      // Listen for changes in the events collection
      const unsubscribe = onSnapshot(eventsRef, async (snapshot) => {
        const now = new Date();
        const eventsList = [];

        for (const doc of snapshot.docs) {
          const eventData = doc.data();
          console.log('Fetched event data:', eventData); // Debugging log

          try {
            const rsvpsRef = collection(db, 'events', doc.id, 'rsvps');
            const rsvpsSnapshot = await getDocs(rsvpsRef);

            // Find RSVP information for the current user
            const guestInfo = rsvpsSnapshot.docs.find(rsvpDoc => {
              const rsvpData = rsvpDoc.data();
              return rsvpData.uid === user.uid || rsvpData.email?.toLowerCase() === user.email?.toLowerCase();
            });

            if (guestInfo) {
              const rsvpData = guestInfo.data();
              console.log(`Found RSVP info for event: ${eventData.name}`, rsvpData); // Debugging log
              const eventDate = new Date(eventData.date);

              // Map the response field to RSVP status
              let rsvpStatus;
              switch (rsvpData.response.toLowerCase()) {
                case 'yes':
                  rsvpStatus = 'confirmed';
                  break;
                case 'no':
                  rsvpStatus = 'declined';
                  break;
                case 'maybe':
                  rsvpStatus = 'maybe';
                  break;
                default:
                  rsvpStatus = 'pending';
                  break;
              }

              const categorizedEvent = {
                id: doc.id,
                ...eventData,
                rsvpStatus,
                isPast: eventDate < now
              };
              console.log(`Event ${eventData.name} RSVP status: ${categorizedEvent.rsvpStatus}`); // Log RSVP status
              eventsList.push(categorizedEvent);
            } else {
              console.log(`RSVP info not found for user ${user.uid} or email ${user.email} in event: ${eventData.name}`); // Debugging log
            }
          } catch (error) {
            console.error(`Error fetching RSVPs for event ${eventData.name}:`, error);
          }
        }

        console.log('Final Events List:', eventsList); // Log the final filtered events list
        setEvents(eventsList);
        setLoading(false);
      }, (error) => {
        console.error('Error fetching events: ', error);
        setLoading(false);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    };

    fetchEvents();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
//format time
  const formatTime = (time) => {
    if (!time) return ''; // Return an empty string if time is undefined
  
    const [hour, minute] = time.split(':');
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? 'PM' : 'AM';
    const formattedHour = hourInt % 12 || 12; // Convert hour to 12-hour format, where 0 becomes 12
    return `${formattedHour}:${minute} ${ampm}`;
  };

  // Filter events based on the selected filter
  const filteredEvents = events.filter(event => {
    if (selectedFilter === 'All') return true;
    if (selectedFilter === 'Past') return event.isPast;
    if (selectedFilter === 'Pending') return event.rsvpStatus === 'pending';
    if (selectedFilter === 'Confirmed') return event.rsvpStatus === 'confirmed' && !event.isPast;
    if (selectedFilter === 'Maybe') return event.rsvpStatus === 'maybe' && !event.isPast;
    if (selectedFilter === 'Declined') return event.rsvpStatus === 'declined' && !event.isPast;
    return false;
  });

  const FilterChip = ({ label }) => (
    <button
      className={`py-2 px-4 rounded-full text-md font-semibold transition ${
        selectedFilter === label ? 'bg-fontGreen text-white text-lg' : 'bg-white text-gray-700'
      }`}
      onClick={() => setSelectedFilter(label)}
    >
      {label}
    </button>
  );

  const EventCard = ({ event }) => (
    <div
      onClick={() => navigate(`/guest-event/${event.id}`)}
      className="bg-gradient-to-r from-fontGreen to-fontGreen text-white p-6 rounded-2xl cursor-pointer hover: transition transform hover:scale-105 relative"
    >
      <h3 className="text-2xl font-bold mb-2 flex items-center gap-2">
      {event.name}
      </h3>
      <p className="text-lg mb-2">{event.type}</p>
      <p className="text-lg">
        Date: <span className="font-medium">{event.date}</span>
      </p>
      <p className="text-lg">
        Time: <span className="font-medium">{formatTime(event.time)}</span>
      </p>  
      <p className="text-lg">
        Location: <span className="font-lg">{event.location}</span>
      </p>
      <span className={`absolute bottom-2 right-2 text-xs text-black font-semibold py-1 px-2 rounded-full ${
        event.rsvpStatus === 'pending' ? 'bg-white' :
        event.rsvpStatus === 'confirmed' ? 'bg-white' :
        event.rsvpStatus === 'declined' ? 'bg-white' :
        event.rsvpStatus === 'maybe' ? 'bg-white' :
        'bg-blue-500'
      }`}>
        {event.rsvpStatus.charAt(0).toUpperCase() + event.rsvpStatus.slice(1)}
      </span>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen">
      {/* Standardized Header */}
      <header className="w-full py-4 bg-white text-fontGreen font-bold flex justify-between items-center px-8 fixed top-0 z-10">
        <img
          src={logo}
          alt="PartySync Logo"
          className="h-16 cursor-pointer"
     //     onClick={() => navigate('/')}
        />
        <button
          className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
          onClick={handleLogout}
        >
        LOG OUT
        </button>
      </header>

      <main className="p-6 min-h-screen bg-gradient-to-b from-offwhite to-offwhite mt-20">
        {/* Back Button Below Header */}
        <div className="flex justify-start items-center mb-4">
          <button
            className="flex items-center gap-2 text-fontGreen font-bold hover:text-fontGreen transition text-lg"
            onClick={() => navigate('/role-selection')}
          >
            <FiArrowLeft size={32} />
          </button>
        </div>

        {/* Title */}
        <h2 className="text-2xl uppercase font-semibold text-primary mb-6">Your Events</h2>

        {/* Filter Chips */}
        <div className="flex gap-2 mb-6">
          {['All', 'Confirmed', 'Pending', 'Maybe', 'Declined', 'Past'].map(filter => (
            <FilterChip key={filter} label={filter} />
          ))}
        </div>

        {/* Event Grid */}
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="animate-pulse bg-fontGreen p-6 rounded-lg shadow-lg"
              >
                <div className="h-6 bg-green rounded w-3/4 mb-2"></div>
                <div className="h-4 bg-green rounded w-1/2 mb-2"></div>
                <div className="h-4 bg-green rounded w-1/4 mb-2"></div>
                <div className="h-4 bg-green rounded w-1/3 mb-2"></div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {filteredEvents.map(event => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>
        )}

        {/* No Events Message */}
        {filteredEvents.length === 0 && !loading && (
          <div className="text-center text-gray-600">No events match your selection.</div>
        )}
      </main>
    </div>
  );
}

export default GuestDashboard;
